<template>
  <div class="pl-2">
    <b-col sm="12" class="p-0">
        <iq-card>
          <div class="iq-card-body profile-page p-0 profilePages listPage">
            <div class="profile-header profile-info">
              <div class="cover-container">
                <img :src="vmBannerImage" class="rounded img-fluid">
              </div>
              <div class="user-detail text-center mb-3">
                <div class="profile-img">
                  <b-avatar variant="primary" class="avatar-100 rounded">
                    <i class="fa fa-university" aria-hidden="true" style="font-size: 35px;"></i>
                  </b-avatar>
                </div>
                <div class="profile-detail">
                  <h3 class="">
                    {{cvCardTitle}}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </iq-card>
    </b-col>
    <b-container fluid class="p-0">
      <b-row >
        <b-col md="12" class="heading_alignment">
          <iq-card style="padding-left:20px; padding-right:20px">
            <div v-if="organisationObjList && organisationObjList.length > 0">
                <b-row class="p-2">
                  <b-col v-for="(org_all,index) in organisationObjList" :key="index + Object.keys(organisationObjList).length" class="mt-5 col-12 col-sm-6 col-md-4">
                    <iq-card className="iq-card-block iq-card-stretch iq-card-height pl-3 pr-3">
                      <template v-slot:body>
                        <div class="iq-badges text-left" @click="viewOrg(org_all)" style="cursor: pointer;">
                          <div class="badges-icon">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org_all.org_profile_img">
                              <img :src="org_all.org_profile_img" class="avatar-80 rounded" alt="Organisation">
                            </b-avatar>
                            <b-avatar v-else class="avatar-80 rounded" :text="getFirstLetterOfAString(org_all.org_name)" style="border-radius: 100%!important;">
                            </b-avatar>
                          </div>
                          <h5 class="mb-2 title homeListShowHeading3Line">
                            {{org_all.org_name}}
                          </h5>
                          <div class="d-flex justify-content-between">
                            <span class="text-uppercase">{{org_all.org_city}} {{org_all.org_city ? ', '+org_all.org_country : org_all.org_country}}</span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </div>
            <template>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-container>
  </div>
</template>
<script>

import { socialvue } from "../config/pluginInit"
import Utility from "../Utils/utility"

export default {
  name: "OrgAccountDashboard",
  // components: {
  //   Teams
  // },
  data () {
    return {
      cvCardTitle: "Organisation Account List",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Account List",
      organisationObjList: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      vmBannerImage: null
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    this.vmBannerImage = Utility.getBgImageForLetter(this, "ORG")
    if (this.userData.modules && this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length) {
      for (let orgId in this.userData.modules.organisations) {
        this.organisationObjList.push(this.userData.modules.organisations[orgId])
      }
    }
  },
  methods: {
    /**
     * viewOrg
     */
    viewOrg (org) {
      this.$router.push(`/organisations/${org.type}/${org.org_id}`)
    },

    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    }
  }
}
</script>
